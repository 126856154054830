<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <img :src="m_zapas_steklo + webp()" alt="" class="im-image" />
    </div>
    <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
      <div class="flex-fill d-flex">
        <div>
          <div class="im-title">Запасное стекло, 1 шт.</div>
          <div class="im-subtitle">
            Стандартное стекло от диоптра DN3” дюйма
          </div>
          <div class="im-text">
            <p>
              Домашний спиртзавод DomSpirt имеет модульную конструкцию из
              стандартных кварцевых стёкол от диоптра стандарта DN 3” дюйма.
              Толстые стенки 5 мм и небольшая высота делают стекло прочным и
              надёжным за счёт продуманной геометрии. А эксплуатация с толстыми
              (2мм) силиконовыми прокладками (они идут в комплекте) позволяет не
              требовать какого-то специального бережного обращения.
            </p>
            <p>
              Однако, если есть необходимость, можно приобрести запасное стекло.
              Стандартное кварцевое стекло может быть использовано как в
              тарельчатой колонне, так и в диоптре 3” дюйма стороннего
              производителя. Высота 40 мм, внутренний диаметр 76 мм, толщина 5
              мм.
            </p>
          </div>
        </div>
      </div>
      <div class="row align-items-center im-delivery-container">
        <div class="col-auto">
          <img :src="car" alt="" class="im-delivery-icon" />
        </div>
        <div class="col col-md-8 im-delivery-text">
          Доставка при заказе с аппаратом DOMSPIRT бесплатная.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const m_zapas_steklo = require("@/pages/Main/assets/modal/m_zapas_steklo.png");
const car = require("@/pages/Main/assets/car.svg");
import webp from "@/functions/webp";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "html",
  data: function () {
    return {
      webp,
      m_zapas_steklo,
      car,
    };
  },
};
</script>

<style scoped></style>
